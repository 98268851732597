import category_people from '../../assets/imgs/people.jpeg';
import category_planets from '../../assets/imgs/planets3.jpeg';
import category_films from '../../assets/imgs/films2.png';
import category_species from '../../assets/imgs/species.webp';
import category_vehicles from '../../assets/imgs/vehicles.jpeg';
import category_starships from '../../assets/imgs/starships6.jpeg';



const Images = {
    people : category_people,
    planets : category_planets,
    films : category_films,
    species : category_species,
    vehicles : category_vehicles,
    starships : category_starships
};

export default Images;